.editor .contents {
  width: 500px;
  height: 400px;
}

.editor .contents .mode {
  padding: 10px 0;
}

.editor .contents .code {
  background: #f5f5f5;
  overflow: auto;
}
