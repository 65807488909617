.uploadFile {
  background: #4285f4;
  padding: 0.5rem 1.6rem;
  font-size: 0.64rem;
  font-family: Roboto, sans-serif;
  color: white;
  margin: 6px;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.12) 0px 2px 10px 0px;
}
